import React from 'react';
//import PropTypes from 'prop-types';
import CardGridA from '../components/CardGridA';

class CertDaysCards extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            adminData: [],
            advAdminData: [],
            mcAdminData: [],
            mCESData: [],
            platformAppBuilderData: [],
            devData: [],
            serviceData: [],
            salesData: []
        };

        this.intval;
    }

    componentDidMount(prevProps, prevState) {
        const { 
            data: {
                administrator,
                advanceAdministrator,
                marketingcloudAdministrator,
                marketingCloudEmailSpecialist,
                platformAppBuilder,
                developer,
                serviceCloudConsultant,
                salesCloudConsultant
            }
        } = this.props;

        const adminData = this.sortAndFilter(administrator);
        const advAdminData = this.sortAndFilter(advanceAdministrator);
        const mcAdminData = this.sortAndFilter(marketingcloudAdministrator);
        const mCESData = this.sortAndFilter(marketingCloudEmailSpecialist);
        const platformAppBuilderData = this.sortAndFilter(platformAppBuilder);
        const devData = this.sortAndFilter(developer);
        const serviceData = this.sortAndFilter(serviceCloudConsultant);
        const salesData = this.sortAndFilter(salesCloudConsultant);
        
        this.setState({
            adminData,
            advAdminData,
            mcAdminData,
            mCESData,
            platformAppBuilderData,
            devData,
            serviceData,
            salesData
        });
    }

    sortAndFilter = (data) => {
        // Actual date -1
        let today = new Date(Date.now());
        today.setHours(0,0,0,0);
        
        const sortedData = data.sort((a,b) => new Date (a.date) - new Date (b.date));
        const filterData = sortedData.filter(data => new Date(data.date) >= today);
        
        return filterData;
    }

    render() {
        const {
            adminData: adminData,
            advAdminData: advAdminData,
            mcAdminData: mcAdminData,
            mCESData: mCESData,
            platformAppBuilderData: platformAppBuilderData,
            devData: devData,
            serviceData: serviceData,
            salesData: salesData
        } = this.state;

        return (
            <>
                {/* Administrator Section */}
                {
                (adminData.length > 0) ? 
                    (
                        <tm-section-a id="administrator" background="light" title="Preparing for your Administrator Certification">
                            <CardGridA dates={adminData}/> 
                        </tm-section-a> 
                    ) : (
                        <tm-section-a id="administrator" background="light" title="Preparing for your Administrator Certification" description="Check back soon as we add more webinars">
                        </tm-section-a>
                    )
                }
                
                {/* Advanced Administrator Section >> DISPLAY JUST WHEN HAVE ITEMS */}
                {advAdminData.length > 0 && (
                    <tm-section-a background="white" title="Preparing for your Advanced Administrator Certification">
                        <CardGridA dates={advAdminData}/> 
                    </tm-section-a> 
                )}

                {/* Marketing Cloud Administrator Section */}
                {
                (mcAdminData.length > 0) ? 
                    (
                        <tm-section-a background="light" title="Preparing for your Marketing Cloud Administrator Certification">
                            <CardGridA dates={mcAdminData}/> 
                        </tm-section-a> 
                    ) : (
                        <tm-section-a background="light" title="Preparing for your Marketing Cloud Administrator Certification" description="Check back soon as we add more webinars">
                        </tm-section-a>
                    )
                }
                
                {/* Marketing Cloud Email Specialist Section */}
                {
                (mCESData.length > 0) ? 
                    (
                        <tm-section-a id="marketing" background="white" title="Preparing for your Marketing Cloud Email Specialist Certification">
                            <CardGridA dates={mCESData}/> 
                        </tm-section-a> 
                    ) : (
                        <tm-section-a id="marketing" background="white" title="Preparing for your Marketing Cloud Email Specialist Certification" description="Check back soon as we add more webinars">
                        </tm-section-a>
                    )
                }

                {/* Platform App Builder Section */}
                {
                (platformAppBuilderData.length > 0) ? 
                    (
                        <tm-section-a id="developer" background="light" title="Preparing for your Platform App Builder Certification">
                            <CardGridA dates={platformAppBuilderData}/> 
                        </tm-section-a> 
                    ) : (
                        <tm-section-a id="developer" background="light" title="Preparing for your Platform App Builder Certification" description="Check back soon as we add more webinars">
                        </tm-section-a>
                    )
                }
                
                {/* Developer Section */}
                {
                (devData.length > 0) ? 
                    (
                        <tm-section-a background="white" title="Preparing for your Platform Developer Certification">
                            <CardGridA dates={devData}/> 
                        </tm-section-a> 
                    ) : (
                        <tm-section-a background="white" title="Preparing for your Platform Developer Certification" description="Check back soon as we add more webinars">
                        </tm-section-a>
                    )
                }
                
                {/* Service Cloud Consultant Section >> DISPLAY JUST WHEN HAVE ITEMS */}
                {
                serviceData.length > 0 && (
                    <tm-section-a id="consultant" background="light" title="Preparing for your Service Cloud Consultant Certification">
                        <CardGridA dates={serviceData}/> 
                    </tm-section-a> 
                )}
                
                {/* Sales Cloud Consultant Section >> DISPLAY JUST WHEN HAVE ITEMS */}
                {
                salesData.length > 0 && (
                    <tm-section-a background="white" title="Preparing for your Sales Cloud Consultant Certification">
                        <CardGridA dates={salesData}/> 
                    </tm-section-a> 
                )}
            </>
        );
    };
}
  
export default CertDaysCards;
