import React from 'react';


const CardGridA = ({dates}) => {
    return (
        <>
            <tm-card-grid-a> 
                {
                    dates.map((item, index) => {
                            return (
                                <tm-grid-item
                                    key = {index}
                                    cta-href = {item.url}
                                    cta-target="_blank" 
                                    cta-title="Register" 
                                    description= {item.time}
                                    title= {item.date}
                                ></tm-grid-item>
                            );
                    })
                }
            </tm-card-grid-a>
        </>
    ); // return
  };
  
  export default CardGridA;
  